export const environment = {
  name: 'production',
  production: true,
  staging: false,
  DATE_FORMAT: 'dd.MM.yyyy',
  BASE_URL: 'https://api.ltytech.ch/',
  USERAPIENDPOINT: 'https://api.ltytech.ch/api/ims/v1/',
  COMMUNICATORAPIURL: 'https://api.ltytech.ch/api/communicator/v1/',
  DOCUMENTAPIURL: 'https://api.ltytech.ch/api/document-store/v1/',
  INSURANCEAPIURL: 'https://api.ltytech.ch/api/insurance/v1/',
  CATALOGUEAPIURL: 'https://api.ltytech.ch/api/catalogue/v1/',
  VERSION: '1.0.2',

  mapsApiKey: 'AIzaSyAwLRt95oBL8bC1NQdu5F_k1MApP8NT4rI',
  mapsApi: 'https://maps.googleapis.com/maps/api/geocode/json',
  tokenValidityTimeMinutes: 60,
  SHOWCASE_WEBSITE_URL: 'https://www.lty.ch/',
};
