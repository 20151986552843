/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { APIResponse, BasicResponse } from './types/api-response.type';
import { PerformAction, ReducedUser, User, UserStage, VerifyOtp } from './types/auth.types';
import { DocumentFetchResponse } from './types/document-store.types';
import { Notification } from './types/notifications.types';
import { Lead, ProposalUpdateRequest } from './types/proposals.type';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  logoutTimeoutId: ReturnType<typeof setTimeout>;

  _headers: any;

  httpOptions: any;

  _headersWithToken: HttpHeaders;

  _headersWithFile: any;

  _baseURL: string;

  _communicatorbaseURL: string;

  _DocumentbaseURL: string;

  _user: ReducedUser;

  deviceInfo: any;

  _headersOTP: any;

  deviceinfoforlogin: any;

  private _openLoginForm = new Subject<void>();

  _InsurancebaseURL: any;

  catalogue: any;

  communication_url: any;

  constructor(
    private httpClient: HttpClient,
    private deviceService: DeviceDetectorService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    this.regenerateHeaders();
  }

  regenerateHeaders() {
    const devicedetail = {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    };
    this.deviceinfoforlogin = devicedetail;
    this._headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(devicedetail));
    this._headersOTP = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('data-hash', 'test')
      .set('device-id', 'test')
      .set('User_Agent', JSON.stringify(devicedetail));

    this._baseURL = environment.USERAPIENDPOINT;
    this._communicatorbaseURL = environment.COMMUNICATORAPIURL;
    this._DocumentbaseURL = environment.DOCUMENTAPIURL;
    this._InsurancebaseURL = environment.INSURANCEAPIURL;
    this.catalogue = environment.CATALOGUEAPIURL;
    this.communication_url = environment.COMMUNICATORAPIURL;

    if (this.isUserLoggedIn()) {
      this.getCurrentUser();
      this._headersWithToken = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Token', this._user.token)
        .set('User_Agent', JSON.stringify(devicedetail));
      this._headersWithFile = new HttpHeaders()
        .set('User_Agent', JSON.stringify(devicedetail))
        .set('Access-Token', this._user.token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isUserLoggedIn(): boolean {
    const data = localStorage.getItem('user');
    if (data !== null) {
      return true;
    }
    return false;
  }

  getCurrentUser(): ReducedUser {
    const data: string = localStorage.getItem('user');
    if (data !== null) {
      this._user = JSON.parse(data);
    }
    return this._user;
  }

  setCurrentUser(user: ReducedUser): void {
    localStorage.setItem('user', JSON.stringify(user));
    this._user = user;
  }

  login(obj: { email: string; password: string }): Observable<APIResponse<UserStage>> {
    const loginheader = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(this.deviceinfoforlogin));

    return this.httpClient.post<APIResponse<UserStage>>(`${this._baseURL}user/signin`, obj, {
      headers: loginheader,
    });
  }

  registration(obj: any): Observable<any> {
    const loginheader = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(this.deviceinfoforlogin));

    return this.httpClient.post(`${this._baseURL}user/signup`, obj, {
      headers: loginheader,
    });
  }

  signout(obj: any): Observable<any> {
    return this.httpClient.post(`${this._baseURL}user/signout`, obj, {
      headers: this._headersWithToken,
    });
  }

  verifyotpwithaction(body: PerformAction): Observable<APIResponse<UserStage>> {
    return this.httpClient.post<APIResponse<UserStage>>(
      `${this._baseURL}user/perform/action`,
      body,
      {
        headers: this._headers,
      },
    );
  }

  resendotp(body: { otpId: string }): Observable<APIResponse<VerifyOtp>> {
    return this.httpClient.put<APIResponse<VerifyOtp>>(
      `${this._communicatorbaseURL}otp/resend`,
      body,
      {
        headers: this._headersOTP,
      },
    );
  }

  getuserByToken(data: { token: string }): Observable<APIResponse<User>> {
    return this.httpClient.post<APIResponse<User>>(`${this._baseURL}user/details/token`, data, {
      headers: this._headersWithToken,
    });
  }

  forgetpassword(obj: any): Observable<any> {
    return this.httpClient.put(`${this._baseURL}user/forgot/password`, obj, {
      headers: this._headers,
    });
  }

  UpdateProfile(obj: any, userId: any): Observable<any> {
    return this.httpClient.put(
      `${this._baseURL}user/update`,
      { userId, userDetail: obj },
      { headers: this._headersWithToken },
    );
  }

  UpdatePassword(obj: any): Observable<any> {
    return this.httpClient.put(
      `${this._baseURL}user/change/password`,
      {
        token: this._user.token,
        oldPassword: obj.oldPassword,
        newPassword: obj.newPassword,
      },
      { headers: this._headersWithToken },
    );
  }

  setpassword(obj: any): Observable<any> {
    return this.httpClient.post(`${this._baseURL}user/verify/forgot/password`, obj, {
      headers: this._headers,
    });
  }

  getFamilymembers(obj: any = ''): Observable<any> {
    const membertype = obj.relation;

    return this.httpClient.post(
      `${this._baseURL}user/get/user/family-member`,
      { userId: this._user.userId, relation: membertype },
      { headers: this._headersWithToken },
    );
  }

  getFamilymember(obj: any = ''): Observable<any> {
    return this.httpClient.post(`${this._baseURL}user/get/family-member`, obj, {
      headers: this._headersWithToken,
    });
  }

  addFamilymembers(obj: any): Observable<any> {
    obj.userId = this._user.userId;

    return this.httpClient.post(`${this._baseURL}user/add/family-member`, obj, {
      headers: this._headersWithToken,
    });
  }

  updateFamilymembers(obj: any): Observable<any> {
    return this.httpClient.put(`${this._baseURL}user/update/family-member`, obj, {
      headers: this._headersWithToken,
    });
  }

  UploadDocument(obj: any): Observable<any> {
    const form = new FormData();
    form.append('docName', obj.docName);
    form.append('userId', this._user?.userId ? this._user?.userId : '');
    form.append('memberId', obj.memberId ? obj.memberId : '');
    form.append('uploadedBy', this._user?.userId ? this._user?.userId : '');
    if (obj.doc !== undefined) {
      form.append('doc', obj.doc, obj.doc.name);
    }

    return this.httpClient.post(`${this._DocumentbaseURL}upload`, form, {
      headers: this._headersWithFile,
    });
  }

  getPresignedFile(obj: any): Observable<any> {
    return this.httpClient.post(`${this._DocumentbaseURL}get/presigned`, obj, {
      headers: this._headersWithToken,
    });
  }

  getLeadList(obj: any): Observable<APIResponse<{ leadsList: Lead[] }>> {
    obj.entityType = 'USER_ID';
    obj.entityId = this._user.userId;
    return this.httpClient.post<APIResponse<{ leadsList: Lead[] }>>(
      `${this._InsurancebaseURL}get/leads`,
      obj,
      {
        headers: this._headersWithToken,
      },
    );
  }

  getProposalOfferList(obj: any): Observable<any> {
    return this.httpClient.post(`${this._InsurancebaseURL}get/lead/user/proposals`, obj, {
      headers: this._headersWithToken,
    });
  }

  checkToken() {
    if (this.isUserLoggedIn()) {
      this.getuserByToken({ token: this.getCurrentUser().token }).subscribe({
        next: () => {},
        error: () => {
          localStorage.removeItem('user');
          window.location.reload();
        },
      });
    }
  }

  addUserFeedback(obj: any): Observable<any> {
    obj.userId = this._user.userId;
    return this.httpClient.post(`${this._baseURL}feedback/add`, obj, {
      headers: this._headersWithToken,
    });
  }

  getUserDocumentsFull(): Observable<APIResponse<DocumentFetchResponse>> {
    return this.httpClient.get<APIResponse<DocumentFetchResponse>>(
      `${this._DocumentbaseURL}get/user/docs/all`,
      {
        headers: this._headersWithToken,
      },
    );
  }

  getLeadsCountOfUser(userId: string): Observable<APIResponse<{ count: number }>> {
    return this.httpClient.get<APIResponse<{ count: number }>>(
      `${this._InsurancebaseURL}get/leads/count/${userId}`,
      {
        headers: this._headersWithToken,
      },
    );
  }

  updateProposalState(
    obj: Omit<ProposalUpdateRequest, 'userId'>,
  ): Observable<APIResponse<BasicResponse>> {
    const fullBody: ProposalUpdateRequest = {
      ...obj,
      userId: this.getCurrentUser().userId,
    };
    return this.httpClient.put<APIResponse<BasicResponse>>(
      `${this._InsurancebaseURL}update/proposal/state`,
      fullBody,
      {
        headers: this._headersWithToken,
      },
    );
  }

  getNotificationList(): Observable<APIResponse<{ notificationList: Notification[] }>> {
    return this.httpClient.get<APIResponse<{ notificationList: Notification[] }>>(
      `${this._communicatorbaseURL}communication/get/notification`,
      {
        headers: this._headersWithToken,
      },
    );
  }

  readNotifications(): Observable<void> {
    return this.httpClient.put<void>(
      `${this._communicatorbaseURL}communication/read/notification`,
      {},
      { headers: this._headersWithToken },
    );
  }

  updateFirstLogin(firstLogin: boolean): Observable<void> {
    return this.httpClient.post<void>(
      `${this._baseURL}user/first-login/update`,
      { userId: this._user.userId, value: firstLogin },
      {
        headers: this._headersWithToken,
      },
    );
  }

  handleTokenInit() {
    if (this.isUserLoggedIn() && this._user.expiresAt) {
      const { expiresAt } = this._user;
      if (!Number.isNaN(expiresAt) && Number(expiresAt) > 0) {
        if (Number(expiresAt) < new Date().getTime()) {
          this.logout();
        } else {
          if (this.logoutTimeoutId) {
            clearTimeout(this.logoutTimeoutId);
          }
          this.logoutTimeoutId = setTimeout(
            () => {
              this.logout();
            },
            Number(expiresAt) - new Date().getTime(),
          );
        }
      }
    }
  }

  logout() {
    localStorage.removeItem('loginTime');
    localStorage.removeItem('user');
    if (this.logoutTimeoutId) {
      clearTimeout(this.logoutTimeoutId);
    }
  }


  // We don't redirect after logout so we don't break the flow in case we are in one
  // We use this function to compare the version in the API to the version in front
  // If it's different, it means that this version of the front is cached so we reload it
  checkVersion() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.httpClient
      .get(`${environment.BASE_URL}version`, { headers, responseType: 'text' })
      .subscribe({
        next: (x) => {
          if (environment.VERSION !== x && !localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', JSON.stringify(true));
            // Reload without cache
            // eslint-disable-next-line no-self-assign
            window.location.href = window.location.href;
          } else {
            localStorage.removeItem('reloaded');
          }
        },
      });
  }
}
