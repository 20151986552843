import { APP_BASE_HREF } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { UserAuthService } from './_services/user-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  title = 'lty';

  tokenValidityTimeMinutes = environment.tokenValidityTimeMinutes;

  constructor(
    router: Router,
    private cookieService: CookieService,
    private authService: UserAuthService,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {
    const languageCode = this.cookieService.get('selectedCountryCode');
    if (!languageCode) {
      this.cookieService.set('selectedCountryCode', 'fr');
    }

    if (this.baseHref === '/') {
      this.cookieService.set('selectedCountryCode', 'fr');
    } else {
      const baseHrefReplaced = this.baseHref.replace(/^\/|\/$/g, '');
      this.cookieService.set('selectedCountryCode', baseHrefReplaced);
    }
    this.authService.handleTokenInit();

    this.authService.checkVersion();
    this.authService.checkToken();
  }

  ngAfterViewInit(): void {
    const w = window as Window &
      typeof globalThis & { ReactNativeWebView: { [key: string]: unknown }; isWebview: boolean };
    setTimeout(() => {
      if (w.isWebview) {
        document.body.classList.add('in-webview-old');
      }

      if (!w.isWebview && !!w.ReactNativeWebView) {
        document.body.classList.add('in-webview-old');
      }
    }, 1000);
  }
}
