export enum HospitalInsuranceEnum {
  GENERAL = 'GENERAL',
  SEMI_PRIVATE = 'SEMI_PRIVATE',
  PRIVATE = 'PRIVATE',
  NONE = 'NONE',
}

export const getHospitalInsuranceEnumLabel = (value: HospitalInsuranceEnum): string => {
  switch (value) {
    case HospitalInsuranceEnum.GENERAL:
      return $localize`Division commune`;
    case HospitalInsuranceEnum.SEMI_PRIVATE:
      return $localize`Demi-privée`;
    case HospitalInsuranceEnum.PRIVATE:
      return $localize`Privé`;
    case HospitalInsuranceEnum.NONE:
      return $localize`Sans assurance hospitalisation`;
    default:
      return value;
  }
};
