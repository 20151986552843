/// <reference types="@angular/localize" />
/// <reference types="@types/google.maps" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://961952fbd26de206c5f0374222b476ca@o4506773103837184.ingest.us.sentry.io/4506897127833600',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    tracePropagationTargets: ['localhost', /^https:\/\/(staging\.)?clients\.lty\.ch/],

    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.name,
  });

  // Adding analytics scripts (prod only)
  if (!environment.staging) {
    const head = document.getElementsByTagName('head')[0];

    const metaScript = document.createElement('script');
    metaScript.innerHTML = `!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '690193565978689');
      fbq('track', 'PageView');
    `;
    const metaNoScript = document.createElement('noscript');
    metaNoScript.innerHTML = `<img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=690193565978689&ev=PageView&noscript=1"
    />`;
    const gtmScript = document.createElement('script');
    gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-619FM8V9ZC';

    const gtmScriptImport = document.createElement('script');
    gtmScriptImport.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-N5PSG6HD');`;

    head.appendChild(metaScript);
    head.appendChild(metaNoScript);
    head.appendChild(gtmScript);
    head.appendChild(gtmScriptImport);

    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N5PSG6HD" height="0" width="0"
      style="display: none; visibility: hidden"></iframe>`;

    const body = document.getElementsByTagName('body')[0];
    body.appendChild(gtmNoScript);
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
