export enum HealthOfferTier {
  BASIC = 'BASIC',
  COMFORT = 'COMFORT',
  PREMIUM = 'PREMIUM',
}

export const getHealthOfferTierLabel = (tier: HealthOfferTier): string => {
  switch (tier) {
    case HealthOfferTier.BASIC:
      return $localize`Basic`;
    case HealthOfferTier.COMFORT:
      return $localize`Confort`;
    case HealthOfferTier.PREMIUM:
      return $localize`Premium`;
    default:
      return '';
  }
};
