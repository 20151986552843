import { Directive, HostBinding, Input } from '@angular/core';
import * as uuid from 'uuid';

export interface IconInputs {
  height: string;
  width: string;
  color: string;
}

@Directive()
export abstract class BaseIconComponent {
  @HostBinding('style.display') public display = 'inline-flex';

  @Input() public height = '35';

  @Input() public width: string;

  @Input() public color = 'currentColor';

  id: string = uuid.v4();
}
